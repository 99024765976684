import {
    Button,
    Dropdown,
    Field,
    makeStyles,
    Option,
    OptionOnSelectData,
    ProgressBar,
    SelectionEvents,
    shorthands,
    tokens,
} from '@fluentui/react-components';
import React, { useCallback, useRef, useState } from 'react';
import { read, utils, WorkBook, WorkSheet, writeFileXLSX } from 'xlsx';
import { SharedStyles } from '../../styles';
import { ChatService } from '../../libs/services/ChatService';
import { AuthHelper } from '../../libs/auth/AuthHelper';
import { useMsal } from '@azure/msal-react';
import { ChatMessageType } from '../../libs/models/ChatMessage';
import { IAskResult } from '../../libs/semantic-kernel/model/AskResult';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: tokens.colorNeutralBackground1,
    },
    header: {
        ...shorthands.borderBottom('1px', 'solid', 'rgb(0 0 0 / 10%)'),
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalM),
        backgroundColor: tokens.colorNeutralBackground1,
        display: 'flex',
        flexDirection: 'row',
        boxSizing: 'border-box',
        width: '100%',
        justifyContent: 'space-between',
    },
    title: {
        ...shorthands.gap(tokens.spacingHorizontalM),
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        fontWeight: tokens.fontWeightSemibold,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        ...shorthands.gap('8px'),
    },
    scroll: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.padding('50px', '15vw'),
        ...shorthands.gap('50px'),
        ...shorthands.margin(tokens.spacingVerticalM),
        ...SharedStyles.scroll,
    },
});

interface SheetRow {
    Date: number;
    Question: string;
    Expected: string;
    Answer: string;
    // ...
}

export const TestWindow: React.FC = () => {
    const classes = useClasses();

    const { instance, inProgress } = useMsal();
    const chatService = new ChatService();

    const [sourceFile, setSourceFile] = React.useState<File | null>(null);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const onSelectSourceFile = () => {
        setSourceFile(fileInputRef.current?.files?.item(0) ?? null);
    };

    const [sourceWorkbook, setSourceWorkbook] = React.useState<WorkBook | null>(null);
    React.useEffect(() => {
        if (!sourceFile) {
            setSourceWorkbook(null);
            return;
        }

        sourceFile
            .arrayBuffer()
            .then((data) => {
                const workbook = read(data);
                setSourceWorkbook(workbook);
            })
            .catch((_) => {});
    }, [sourceFile]);

    React.useEffect(() => {
        if (!sourceWorkbook) {
            return;
        }
    }, [sourceWorkbook]);

    const [selectedSheet, setSelectedSheet] = useState<WorkSheet | null>(null);
    const onSelectSheet = useCallback(
        (_: SelectionEvents, data: OptionOnSelectData) => {
            const sheet = sourceWorkbook?.Sheets[data.optionValue as string] ?? null;
            setSelectedSheet(sheet);
        },
        [sourceWorkbook?.Sheets, setSelectedSheet],
    );

    const [sheetData, setSheetData] = useState<SheetRow[]>([]);
    React.useEffect(() => {
        if (!selectedSheet) {
            return;
        }

        const data: SheetRow[] = utils.sheet_to_json<SheetRow>(selectedSheet, {
            header: ['Date', 'Question' /*, "Expected", "Answer"*/],
            skipHidden: true,
            blankrows: false,
        });

        // remove header
        data.shift();

        // expand merged questions
        let lastQuestion: string;
        data.forEach((row) => (lastQuestion = row.Question = row.Question ? row.Question : lastQuestion));

        setSheetData(data);
    }, [selectedSheet]);

    const [isCollectingResponses, setIsCollectingResponses] = React.useState<boolean>(false);
    const [collectionProgress, setCollectionProgress] = React.useState<number>(0);
    const [abortController, setAbortController] = React.useState<AbortController | null>(null);
    const onHandleGenerateResponses = async (signal: AbortSignal) => {
        setCollectionProgress(0);
        setIsCollectingResponses(true);

        try {
            for (let i = 0; i < sheetData.length; i++) {
                if (signal.aborted) {
                    stop();
                    throw new Error('Aborted');
                }

                const row = sheetData[i];

                const sessionId = await chatService
                    .createChatAsync('Test', await AuthHelper.getSKaaSAccessToken(instance, inProgress))
                    .then((res) => res.chatSession.id);

                try {
                    const assistantMessage: IAskResult & { value?: string } = await chatService.getBotResponseAsync(
                        {
                            input: row.Question,
                            variables: [
                                {
                                    key: 'chatId',
                                    value: sessionId,
                                },
                                {
                                    key: 'messageType',
                                    value: ChatMessageType.Message.toString(),
                                },
                            ],
                        },
                        await AuthHelper.getSKaaSAccessToken(instance, inProgress),
                    );

                    row.Answer = assistantMessage.value as string;
                } catch (e: any) {
                    row.Answer = (e as Error).message;
                }

                await chatService.deleteChatAsync(
                    sessionId,
                    await AuthHelper.getSKaaSAccessToken(instance, inProgress),
                );

                setCollectionProgress(i + 1);
            }

            // create and download output
            const book = utils.book_new();
            const sheet = utils.aoa_to_sheet(sheetData.map((row) => [row.Question, row.Answer]));
            utils.book_append_sheet(book, sheet);
            writeFileXLSX(book, `${window.location.hostname}.tests.xlsx`, { compression: true });
        } catch (_) {
        } finally {
            setIsCollectingResponses(false);
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.title}>Test</div>
                <div className={classes.controls}></div>
            </div>
            <div className={classes.scroll}>
                <input
                    ref={fileInputRef}
                    type="file"
                    onChange={onSelectSourceFile}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    style={{ display: 'none' }}
                />

                <Field label="1. Datei auswählen">
                    <Button
                        onClick={() => {
                            fileInputRef.current?.click();
                        }}
                        disabled={isCollectingResponses}
                    >
                        Quelle (XLSX)
                        {fileInputRef.current?.files?.[0]?.name !== undefined &&
                            `: ${fileInputRef.current.files[0].name}`}
                    </Button>
                </Field>

                {sourceWorkbook?.SheetNames.length && (
                    <>
                        <Field
                            label="2. Sheet als Quelle der Fragen auswählen"
                            hint="Nur Zeilen mit Wert in Spalte A werden berücksichtigt. Es wird die Spalte B als Frage benutzt."
                        >
                            <Dropdown
                                onOptionSelect={onSelectSheet}
                                placeholder="Testergebniss Sheet auswählen"
                                disabled={isCollectingResponses}
                            >
                                {sourceWorkbook.SheetNames.map((option) => (
                                    <Option key={option}>{option}</Option>
                                ))}
                            </Dropdown>
                        </Field>
                    </>
                )}

                {sheetData.length > 0 && (
                    <>
                        <Field label="2. Antworten sammeln und als XLSX speichern">
                            <Button
                                onClick={() => {
                                    const newAbortController = new AbortController();
                                    setAbortController(newAbortController);
                                    void onHandleGenerateResponses(newAbortController.signal);
                                }}
                                disabled={isCollectingResponses}
                            >
                                Antworten generieren
                            </Button>
                        </Field>
                    </>
                )}

                {isCollectingResponses && (
                    <>
                        <Field hint={`${collectionProgress + 1} von ${sheetData.length}`}>
                            <ProgressBar max={sheetData.length} value={collectionProgress + 1} />
                        </Field>
                        <Button
                            onClick={() => {
                                abortController?.abort();
                            }}
                        >
                            Abbrechen
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};
