import { TooltipProps as ChartTooltipProps } from 'recharts/types/component/Tooltip';
import React from 'react';

interface TopTenTooltipProps extends ChartTooltipProps<number, string> {
    showDimension: boolean;
}

export const TopTenTooltip: React.FC<TopTenTooltipProps> = ({ active, payload, label, showDimension }) => {
    if (active && payload?.length) {
        // Sort the payload data and take the top 10 items
        const top10 = payload.sort((a, b) => (b.value as number) - (a.value as number)).slice(0, 10);
        const total = payload.map((entry) => entry.value ?? 0).reduce((acc, cur) => acc + cur, 0);

        return (
            <div style={{ border: '1px solid #888', background: '#fff', padding: '0 16px 8px' }}>
                <p className="label">{labelFormatter(Number(label))}</p>
                {showDimension && (
                    <>
                        <p>Summe: {total}</p>
                        <div>
                            Top 10:
                            {top10.map((entry, index) => (
                                <dl
                                    key={`item-${index}`}
                                    style={{ margin: '2px 10px' }}
                                >{`${entry.name}: ${entry.value}`}</dl>
                            ))}
                        </div>
                    </>
                )}
                {!showDimension && <>{total}</>}
            </div>
        );
    }

    return null;
};

function labelFormatter(value: number): string {
    const date = new Date(value);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}
