import {
    makeStyles,
    shorthands,
    tokens,
    Divider,
    Button,
    Tooltip,
    TableColumnDefinition,
    createTableColumn,
    DataGrid,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridRow,
    DataGridBody,
    DataGridCell,
    DataGridProps,
} from '@fluentui/react-components';
import React, { useState } from 'react';
import { useLog } from '../../libs/hooks';
import { ReadLogsResponseResultRecord } from '../../libs/services/LogService';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip as ChartTooltip, XAxis, YAxis } from 'recharts';
import { Loading } from '../views';
import { KpiDefinition } from './KpiWindow';
import * as XLSX from 'xlsx';
import { ArrowDownloadRegular } from '@fluentui/react-icons';
import { TopTenTooltip } from './TopTenTooltips';
import { Filter } from './Filter';

export interface KpiPanelProps {
    kpi: KpiDefinition;
    from: Date;
    to: Date;
}

const useClasses = makeStyles({
    root: {
        width: '100%',
        ...shorthands.margin('12px'),
    },
    header: {
        fontSize: tokens.fontSizeBase500,
        fontWeight: tokens.fontWeightMedium,
        lineHeight: tokens.fontSizeHero900,
    },
    title: {
        textAlign: 'center',
    },
    divider: {
        ...shorthands.padding('24px', 0),
    },
    tableToolbar: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap('4px'),
    },
});

interface SeriesEntry {
    d: number;

    [key: string]: number | undefined;
}

const lineColors = [
    '#993300',
    '#333300',
    '#003300',
    '#003366',
    '#333399',
    '#333333',
    '#800000',
    '#FF6600',
    '#808000',
    '#008000',
    '#008080',
    '#666699',
    '#808080',
    '#339966',
    '#33CCCC',
    '#3366FF',
    '#800080',
    '#969696',
    '#FFCC00',
    '#993366',
];

function getColor(index: number): string {
    return lineColors[index] ?? lineColors[lineColors.length - 1];
}

function tickFormatter(value: number): string {
    const date = new Date(value);
    return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

function dateFormatter(date: Date): string {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

function generateEmptySeriesDataForRange(from: Date, to: Date): SeriesEntry[] {
    const list: SeriesEntry[] = [];
    for (const date = new Date(from); date < to; date.setDate(date.getDate() + 1)) {
        list.push({
            d: date.setHours(0, 0, 0, 0),
        });
    }
    return list;
}

function getSeriesListFromChartData(seriesEntries: SeriesEntry[]): string[] {
    const list: string[] = [];

    seriesEntries.forEach((entry) => {
        Object.keys(entry).forEach((key) => {
            if (key !== 'd' && !list.includes(key)) {
                list.push(key);
            }
        });
    });

    return list;
}

export const KpiPanel: React.FC<KpiPanelProps> = ({ kpi, to, from }) => {
    const classes = useClasses();

    const log = useLog();

    const [chartData, setChartData] = React.useState<SeriesEntry[]>([]);
    const [tableData, setTableData] = React.useState<ReadLogsResponseResultRecord[]>([]);
    const [filteredTableData, setFilteredTableData] = React.useState<ReadLogsResponseResultRecord[]>([]);
    const [filterDimension, setFilterDimension] = React.useState<string[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const showDimension = kpi.dimension !== undefined;

    React.useEffect(() => {
        setIsLoading(true);
        log.readLogs(kpi.type, from, to)
            .then((r) => {
                setFilterDimension([]);
                setTableData(r.result);
            })
            .catch((_) => {})
            .finally(() => {
                setIsLoading(false);
            });

        // We don't want to have log as one of the dependencies as it will cause infinite loop.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kpi, to, from]);

    React.useEffect(() => {
        let filteredTableData = tableData;
        if (filterDimension.length > 0) {
            filteredTableData = tableData.filter((value) => filterDimension.includes(value.dimension));
        }
        setFilteredTableData(filteredTableData);

        const series: SeriesEntry[] = generateEmptySeriesDataForRange(from, to);

        filteredTableData.forEach((value) => {
            const date = new Date(value.date).setHours(0, 0, 0, 0);
            const existingItem = series.find((item) => item.d === date);

            if (existingItem) {
                existingItem[value.dimension] = (existingItem[value.dimension] ?? 0) + value.count;
            } else {
                const item: SeriesEntry = {
                    d: date,
                };
                item[value.dimension] = value.count;
                series.push(item);
            }
        });

        setChartData(series);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData, filterDimension]);

    const [columns, setColumns] = useState<Array<TableColumnDefinition<ReadLogsResponseResultRecord>>>([]);
    React.useEffect(() => {
        const columns: Array<TableColumnDefinition<ReadLogsResponseResultRecord>> = [
            createTableColumn<ReadLogsResponseResultRecord>({
                columnId: 'date',
                compare: (a, b) => {
                    return a.date.localeCompare(b.date);
                },
                renderHeaderCell: () => {
                    return 'Datum';
                },
                renderCell: (item) => {
                    return dateFormatter(new Date(item.date));
                },
            }),
            ...(kpi.dimension
                ? [
                      createTableColumn<ReadLogsResponseResultRecord>({
                          columnId: 'dimension',
                          compare: (a, b) => {
                              return a.dimension.localeCompare(b.dimension);
                          },
                          renderHeaderCell: () => {
                              return kpi.dimension;
                          },
                          renderCell: (item) => {
                              return item.dimension;
                          },
                      }),
                  ]
                : []),
            createTableColumn<ReadLogsResponseResultRecord>({
                columnId: 'value',
                compare: (a, b) => {
                    return a.count - b.count;
                },
                renderHeaderCell: () => {
                    return kpi.value;
                },

                renderCell: (item) => {
                    return item.count;
                },
            }),
        ];

        setColumns(columns);
    }, [kpi]);

    const downloadXSLX = () => {
        const data = filteredTableData.map((record) => {
            return {
                Datum: dateFormatter(new Date(record.date)),
                ...(kpi.dimension ? { [kpi.dimension]: record.dimension } : {}), // is optional
                [kpi.value]: record.count,
            };
        });

        const now = new Date();
        const dateTimeString = [
            now.getFullYear(),
            (now.getMonth() + 1).toString().padStart(2, '0'),
            now.getDate().toString().padStart(2, '0'),
            now.getHours().toString().padStart(2, '0'),
            now.getMinutes().toString().padStart(2, '0'),
            now.getSeconds().toString().padStart(2, '0'),
        ].join('');

        // eslint-disable-next-line
        const worksheet = XLSX.utils.json_to_sheet(data);
        // eslint-disable-next-line
        const workbook = XLSX.utils.book_new();
        // eslint-disable-next-line
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        // eslint-disable-next-line
        XLSX.writeFileXLSX(workbook, `${dateTimeString} ${kpi.label}.xlsx`, { compression: true });
    };

    const defaultSortState = React.useMemo<Parameters<NonNullable<DataGridProps['onSortChange']>>[1]>(
        () => ({ sortColumn: 'date', sortDirection: 'ascending' }),
        [],
    );

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.title}>{kpi.label}</div>
            </div>
            {!isLoading && (
                <div>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={chartData} margin={{ top: 4, right: 24 }}>
                            <XAxis
                                dataKey="d"
                                scale="time"
                                type="number"
                                domain={[from.setHours(0, 0, 0, 0), to.setHours(0, 0, 0, 0)]}
                                tickFormatter={tickFormatter}
                                padding="gap"
                            />
                            <YAxis allowDecimals={false} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <ChartTooltip
                                content={<TopTenTooltip showDimension={showDimension} />}
                                wrapperStyle={{ zIndex: 9999 }}
                            />
                            {chartData.length &&
                                getSeriesListFromChartData(chartData).map((key, index) => (
                                    <Line key={key} type="monotone" dataKey={key} stroke={getColor(index)} />
                                ))}
                        </LineChart>
                    </ResponsiveContainer>

                    <Divider className={classes.divider} />

                    <div className={classes.tableToolbar}>
                        <div style={{ flexGrow: 1 }}></div>
                        {showDimension && (
                            <Filter
                                options={[...new Set(tableData.map((v) => v.dimension))]}
                                onChange={setFilterDimension}
                            />
                        )}
                        <Tooltip content="Tabelle als Excel Datei herunterladen" relationship="label" withArrow>
                            <Button
                                icon={<ArrowDownloadRegular />}
                                onClick={() => {
                                    downloadXSLX();
                                }}
                            >
                                .xlsx
                            </Button>
                        </Tooltip>
                    </div>

                    <DataGrid
                        size="small"
                        items={filteredTableData}
                        columns={columns}
                        sortable
                        defaultSortState={defaultSortState}
                    >
                        <DataGridHeader>
                            <DataGridRow>
                                {({ renderHeaderCell }) => (
                                    <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                )}
                            </DataGridRow>
                        </DataGridHeader>
                        <DataGridBody<ReadLogsResponseResultRecord>>
                            {({ item, rowId }) => (
                                <DataGridRow<ReadLogsResponseResultRecord> key={rowId}>
                                    {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
                                </DataGridRow>
                            )}
                        </DataGridBody>
                    </DataGrid>
                </div>
            )}
            {isLoading && <Loading text="Lade Daten ..." />}
        </div>
    );
};
