import { BaseService } from './BaseService';

export interface WriteLogResponse {
    isSuccess: boolean;
}

export interface ReadLogsResponseResultRecord {
    date: string;
    dimension: string;
    count: number;
}

export interface ReadLogsResponse {
    result: ReadLogsResponseResultRecord[];
}

export enum LogEventType {
    SessionCreated = 'SessionCreated',
    MessageQueried = 'MessageQueried',
    UserActive = 'UserActive',
    PresetUsed = 'PresetUsed',
    ReferenceOpened = 'ReferenceOpened',
    ReferenceUsed = 'ReferenceUsed',
    TokenResponseTime = 'TokenResponseTime',
}

export class LogService extends BaseService {
    writeLog = async (
        eventType: LogEventType,
        triggeredBy: string | null,
        triggeredFor: string | null,
        accessToken: string,
    ): Promise<WriteLogResponse> => {
        return await this.getResponseAsync<WriteLogResponse>(
            {
                commandPath: `log`,
                method: 'POST',
                body: {
                    eventType,
                    triggeredBy,
                    triggeredFor,
                },
            },
            accessToken,
        );
    };

    readLogs = async (
        eventType: LogEventType,
        from: Date,
        to: Date,
        accessToken: string,
    ): Promise<ReadLogsResponse> => {
        return await this.getResponseAsync<ReadLogsResponse>(
            {
                commandPath: `logs`,
                method: 'GET',
                query: new URLSearchParams([
                    ['type', eventType],
                    ['from', from.toISOString()],
                    ['to', to.toISOString()],
                ]),
            },
            accessToken,
        );
    };
}
