import React from 'react';
import {
    Avatar,
    Button,
    makeStyles,
    Tag,
    TagPicker,
    TagPickerControl,
    TagPickerGroup,
    TagPickerInput,
    TagPickerList,
    TagPickerOption,
    TagPickerProps,
    useTagPickerFilter,
} from '@fluentui/react-components';
import { FilterDismissRegular } from '@fluentui/react-icons';

interface FilterProps {
    options: string[];
    onChange: (selected: string[]) => void;
}

const useStyles = makeStyles({
    optionContent: {
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
    },
});

export const Filter: React.FC<FilterProps> = ({ options, onChange }) => {
    const classes = useStyles();

    const [query, setQuery] = React.useState<string>('');
    const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);

    const onOptionSelect: TagPickerProps['onOptionSelect'] = (_, data) => {
        if (data.value === 'no-matches') {
            setSelectedOptions([]);
            return;
        }

        setSelectedOptions(data.selectedOptions);
        setQuery('');
    };

    React.useEffect(() => {
        onChange(selectedOptions);
    }, [onChange, selectedOptions]);

    const sortedOptions = options.sort((a, b) => a.localeCompare(b));

    const children = useTagPickerFilter({
        query,
        options: sortedOptions,
        noOptionsElement: <TagPickerOption value="no-matches">Kein Eintrag vorhanden</TagPickerOption>,
        renderOption: (option) => (
            <TagPickerOption
                media={<Avatar shape="square" aria-hidden name={option} color="colorful" />}
                key={option}
                value={option}
                text={option}
                title={option}
            >
                <div className={classes.optionContent}>{option}</div>
            </TagPickerOption>
        ),

        filter: (option) => !selectedOptions.includes(option) && option.toLowerCase().includes(query.toLowerCase()),
    });

    const handleAllClear: React.MouseEventHandler = (_) => {
        setSelectedOptions([]);
        setQuery('');
    };

    return (
        <TagPicker onOptionSelect={onOptionSelect} selectedOptions={selectedOptions}>
            <TagPickerControl
                secondaryAction={
                    <Button
                        appearance="transparent"
                        size="small"
                        shape="rounded"
                        icon={<FilterDismissRegular />}
                        onClick={handleAllClear}
                        style={{ visibility: selectedOptions.length ? 'visible' : 'hidden' }}
                    />
                }
            >
                <TagPickerGroup>
                    {selectedOptions.map((option) => (
                        <Tag
                            key={option}
                            shape="rounded"
                            media={<Avatar aria-hidden name={option} color="colorful" />}
                            value={option}
                        >
                            {option}
                        </Tag>
                    ))}
                </TagPickerGroup>
                <TagPickerInput
                    value={query}
                    onChange={(e) => {
                        setQuery(e.target.value);
                    }}
                    clearable
                    placeholder={!selectedOptions.length ? 'Filter' : ''}
                />
            </TagPickerControl>

            <TagPickerList>{children}</TagPickerList>
        </TagPicker>
    );
};
